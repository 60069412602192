import * as Yup from "yup";
import {
  EMAIL_REGEX,
  MOBILE_REGEX,
  NOSPACE_REGEX,
} from "./constants";
import { MESSAGE } from "./validationMessage";
import { stripHtml } from "string-strip-html"; // Import stripHtml function

let title = Yup.string()
  .max(60, "This field should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let status = Yup.mixed().required(MESSAGE.REQUIRED);


export const CmsSchema = Yup.object({
  title,
  status,
  description: Yup.string()
  .required('Description is required')
  .test('no-spaces-at-start', 'Spaces are not allowed before content', (value) => {
    const strippedValue = stripHtml(value || '').result;
    return strippedValue.trimStart().length > 0; 
  })
  .test('min-length', 'Description must be at least 10 characters', (value) => {
    const strippedValue = stripHtml(value || '').result;
    return strippedValue.trim().length >= 10; 
  })
  .test(
    'is-empty-html',
    'Description cannot be empty',
    (value) => {
      const strippedValue = stripHtml(value || '').result;
      return strippedValue.trim() !== ''; 
    }
  )
  .test('no-nbsp', 'Description cannot contain only spaces', (value) => {
    const strippedValue = stripHtml(value || '').result;
    return strippedValue.replace(/<\/?[^>]+(>|$)/g, '').length > 0; 
  }),


});

export const bannerAndOffer = (id) => Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Banner name is too short. It must be at least 3 characters long.')
    .max(100, 'Banner name is too long. It cannot exceed 100 characters.')
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),

    description: Yup.string().required("Description is required").matches(/^[^\s][\s\S]*$/  , "Spaces are not allowed at the start of the text."),
  
    startDate: Yup.date()
    .test('required-if-no-id', 'Start Date is required', function (value) {
      const { path, createError } = this;
      if (!id) {
        if (!value) {
          return createError({ path, message: 'Start Date is required' });
        }
        const currentDate = new Date();
        if (new Date(value) < currentDate) {
          return createError({ path, message: 'Start Date cannot be in the past' });
        }
      }
      return true;
    }),

  endDate: Yup.date()
    .test('required-if-no-id', 'End Date is required', function (value) {
      const { path, createError } = this;
      const { startDate } = this.parent;
      if (!id) {
        if (!value) {
          return createError({ path, message: 'End Date is required' });
        }
        // Check if endDate is at least 30 minutes after startDate
        const startTime = new Date(startDate);
        const endTime = new Date(value);
        if (endTime <= startTime || (endTime - startTime) < 30 * 60 * 1000) {
          return createError({ path, message: 'End Date must be at least 30 minutes after Start Date' });
        }
      }
      return true;
    }),


  
  offerType: Yup.string()
    .required('Offer Type is required'),

  couponCode: Yup.string()
    .min(6, 'Coupon Code should be at least 6 characters long')
    .max(8, 'Coupon Code should be at most 8 characters long')
    .matches(/^[A-Za-z0-9]*$/, 'Coupon Code can only contain letters and numbers, no spaces or special characters')
    .test('couponCode', 'Coupon Code is required', function(value) {
      const { offerType } = this.parent; 
      if (offerType === 'offer') {
        return value && value.trim() !== '';
      }
      return true;
    }),

  couponType: Yup.string()
    .test('couponType', 'Coupon Type is required', function(value) {
      const { offerType } = this.parent; 

      if (offerType === 'offer') {
        return value && value.trim() !== '';
      }
      return true;
    }),

  discount: Yup.string()
    .test('discount', 'Discount is required', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        return value && value.trim() !== '';
      }
      return true;
    })
    .matches(/^[1-9]\d*$/, 'Discount must be a valid number without leading zeros or repeated digits')
    .test('isValidNumber', 'Discount must be between 0 and 100', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        const numValue = parseInt(value, 0);
        return numValue >= 0 && numValue <= 100;
      }
      return true;

  }),

  minimumPrice: Yup.string()
    .test('minimumPrice', 'Minimum Price is required', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        return value && value.trim() !== '';
      }
      return true;
    })
    .matches(/^[1-9]\d*$/, 'Minimum price must be a valid number without leading zeros or repeated digits')
    .test('isValidNumber', 'Minimum price must be between 10 and 100', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        const numValue = parseInt(value, 10);
        return numValue >= 10 && numValue <= 100;
      }
      return true;

    }),

  purchaseProductIds: Yup.array()
    .of(Yup.string().required('You must select a Purchase Product'))
    .test('purchaseProductIds', 'You must select a Purchase Product', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'B1G1' || couponType === 'B1GO') {
        return value && value.length > 0; 
      }
      return true;
  }),

  freeProductId: Yup.mixed()
    .test('freeProductId', 'You must select a Free Product', function(value) {
      const { couponType } = this.parent;
      if (couponType === 'B1GO') {
        if (!value || (Array.isArray(value) && value.length === 0)) {
          return false;
        }
        return Array.isArray(value) && value.length > 0 && typeof value[0] === 'string' && value[0].trim().length > 0;
      }
      return true;
  }),

  image: Yup.mixed()
    .test('image', 'Image is required', function(value) {
      const imagePreview = this.parent.imagePreview;
      return imagePreview || value != null; 
    }),
});


export const category = Yup.object().shape({
  name: Yup.string()
    // .trim()
    .required('Name is required')
    .min(3, 'Name should be at least 3 characters long')
    .max(50, 'Name should be at most 50 characters long')
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),

  image: Yup.mixed()
    .test('image', 'Image is required', function(value) {
      const imagePreview = this.parent.imagePreview;
      return imagePreview || value != null; 
    }),
})

export const productValidation = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .min(2, "Name must be at least 2 characters")
    .test("name", "Name should be less than 100 characters", (values) => {
      if (values.length >= 100) {
        return false;
      } else {
        return true;
      }
    }),
  qty: Yup.string().required("Quantity is required"),
  weight: Yup.string()
  .test(
    "selectedCategoryName",
    "Weight is required",
    (value, context) => {
      console.log()
      if (context.parent.selectedCategoryName == "Cylinders") {
        return value !== undefined && value !== "";
      }
      return true; 
    }
  ),
  productModel: Yup.string()
  .trim()
  .required("Product Model is required")
  .matches(/^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/, "Product Model must be alphanumeric.")
  .min(2).max(20),

  price: Yup.number()
    .required("Price is required")
    .min(1, "Price must be greater than 0"),
  image: Yup.mixed().required("Product image is required"),
  description: Yup.string().required("Description is required").matches(/^[^\s][\s\S]*$/  , "Spaces are not allowed at the start of the text."),
  categoryId: Yup.string().required("Category is required"),
})

export const dealerSchema = Yup.object().shape({
  businessName: Yup.string()
    .required("Business Name is required")
    .min(2,   "Business Name must be atleast 2 character")
    .max(55,  `Business Name must not be more than 55 characters.`)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
  email: Yup.string()
    .required("Email is required")
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .matches(EMAIL_REGEX,'Invalid email format'),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/,"Phone number must be 10 digits long and contain only numbers"),

  dealerId: Yup.string()
    .required("Dealer Id  is required")
    .min(6,"Please enter 6 digits Dealer Id."),

  documents: Yup.mixed()
    .required("Document is required")
    .test("required", "Document is required", (value) => {
      return value !== null && value !== "";
    })
    
});

export const CustomerSchema = Yup.object().shape({

  name: Yup.string()
    .required('Name is required')
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .min(2, "Name must be atleast 2 character")
    .max(55, "Name should must not be more than 55 characters."),

  email: Yup.string()
    .notRequired()
    .matches(EMAIL_REGEX, MESSAGE.EMAIL)
    .email("Invalid email format"),  

  phoneNumber: Yup.string()
    .matches(MOBILE_REGEX, 'Phone number is not valid')
    .required('Phone is required'),
    
  registrationNumber: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'ID No./Registration No. should only contain alphanumeric characters')
    .min(6, 'ID No./Registration No. should be between 6-10 characters')
    .max(10, 'ID No./Registration No. should be between 6-10 characters')
    .required('ID No./Registration No. is required'),

  flatNumber: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\/]+$/, 'Flat No./Plot No. should contain only alphanumeric characters, spaces, hyphens, or slashes')
    .min(1, 'Flat No./Plot No. must be atleast 1 character')
    .max(10, 'Flat No./Plot No. must not be more than 10 characters.')
    .required('Flat No./Plot No. is required')
    .test('no-trailing-spaces', 'Flat No./Plot No cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    ),
    
  area: Yup.string()
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'Area should contain only alphanumeric characters,hyphens, or apostrophes')
    .min(2, 'Area must be atleast 1 character')
    .max(50,'Area must not be more than 10 characters.')
    .test('not-purely-numeric', 'Area cannot be purely numeric', (value) => isNaN(Number(value)))
    .required('Area is required'),

  locality: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'Locality should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(3, 'Locality must be atleast 1 character')
    .max(50, 'Locality must not be more than 50 characters.')
    .test('not-purely-numeric', 'Locality cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-trailing-spaces', 'Locality cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('Locality is required'),

  landmark: Yup.string()
    .matches(/^[a-zA-Z0-9\s,\'\-]+$/, 'Landmark should contain only alphanumeric characters, spaces, commas, hyphens, or apostrophes')
    .min(3, 'Landmark must be atleast 3 character')
    .max(100, 'Landmark must not be more than 100 characters.')
    .test('not-purely-numeric', 'Landmark cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'Landmark cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'Landmark cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s,\'\-]|[\s,\'\-]$/.test(value) : true
    )
    .required('Landmark is required'),

  city: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'City should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'City must be atleast 2 character')
    .max(50, 'City must not be more than 50 characters.')
    .test('not-purely-numeric', 'City cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'City cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'City cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('City is required'),

  village: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'Village should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'Village must be atleast 2 character')
    .max(50, 'Village must not be more than 50 characters.')
    .test('not-purely-numeric', 'Village cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'Village cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'Village cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('Village is required'),

  state: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'State should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'State must be atleast 2 character')
    .max(50, 'State must not be more than 50 characters.')
    .test('not-purely-numeric', 'State cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'State cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'State cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('State is required'),

  pinCode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pin Code should be 6 digits and contain only numbers')
    .required('Pin Code is required'),
});


export const validationFaqSchema = Yup.object().shape({
  title: Yup.string()
    .required("Question is required")
    .matches(NOSPACE_REGEX, "Spaces are not allowed at the start or end of the text.")
    .min(2, "Please enter at least 2 characters")
    .max(100, "Question must not be more than 100 characters."),
    description: Yup.string()
    .required('Description is required')
    .test('no-spaces-at-start', 'Spaces are not allowed before content', (value) => {
      const strippedValue = stripHtml(value || '').result;
      
      return !/^\s/.test(strippedValue); 
    })    

    .test('min-length', 'Description must be at least 10 characters', (value) => {
      const strippedValue = stripHtml(value || '').result;
      return strippedValue.trim().length >= 10; 
    })
    .test('max-length', 'Description must not exceed 1000 characters', (value) => {
      const strippedValue = stripHtml(value || '').result;
      return strippedValue.trim().length <= 1000; 
    }),
  });



  export const driverSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2,   "Name must be atleast 2 character")
      .max(55,  `Name must not be more than 55 characters.`)
      .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
      .required("Email is required")
      .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
      .matches(EMAIL_REGEX,'Invalid email format'),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/,"Phone number must be 10 digits long and contain only numbers"),
      address: Yup.string()
      .matches(/^(?!\s*$)[a-zA-Z0-9\s\-\'\,\.\#\/]*$/)
      .min(2, 'Address must be at least 2 characters long')
      .max(50, 'Address must not exceed 50 characters')
      .test(
        'not-only-special-characters',
        'Address cannot contain only special characters',
        (value) => /[a-zA-Z0-9]/.test(value) // Ensures at least one alphanumeric character is present
      )
      .test(
        'not-purely-numeric',
        'Address cannot be purely numeric',
        (value) => value && isNaN(Number(value))
      )
      .required('Address is required'),
    dealerId: Yup.string()
      .required("Dealer Id  is required")
      .min(6,"Please enter 6 digits Dealer Id."),
    
      drivingLicense: Yup.mixed()
      .required("Driving License is required")
      .test("fileType", "Only PDF files are allowed", (value) => {
        if (!value) return false;
        if (typeof value === "string") return true; // Already a valid path
        return value?.type === "application/pdf"; // Check uploaded file type
      }),
    idProof: Yup.mixed()
      .required("ID Proof is required")
      .test("fileType", "Only PDF files are allowed", (value) => {
        if (!value) return false;
        if (typeof value === "string") return true; // Already a valid path
        return value?.type === "application/pdf"; // Check uploaded file type
      }), 
      
  });

  export const EmailTemplateSchema = Yup.object({
    title: Yup.string()
      .min(2, "Title must be longer than 2 characters")
      .max(100, "Title should not be longer than 100 characters")
      .required("Title is required")
      .matches(NOSPACE_REGEX, "Title cannot start or end with a blank space."),
    subject: Yup.string()
      .min(2, "Subject must be longer than 2 characters")
      .max(100, "Subject should not be longer than 100 characters")
      .required("Subject is required")
      .matches(NOSPACE_REGEX, "Subject cannot start or end with a blank space."),
    description: Yup.string()
      .min(10, "Description must be longer than 10 characters")
      .max(500, "Description should not be longer than 500 characters")
      .required("Description is required")
      .matches(NOSPACE_REGEX, "Description cannot start or end with a blank space."),
  
  })