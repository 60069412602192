import { Avatar, Box, Button, CircularProgress, Grid } from "@mui/material";
import { capitalize } from "./helpers";

export const handleNumberInput = (e, formikname, formik, allowDecimal, limit) => {
    const re = allowDecimal ? new RegExp(`^\\d*\\.?\\d{0,${limit}}$`) : new RegExp(`^[0-9]\\d{0,${limit - 1}}$`);
    if (e.target.value === '' || re.test(e.target.value)) {
        formik.setFieldValue(formikname, e.target.value);
    } else {
        return false;
    }
}



export const formatOrderStatus = (status) => {
    const statusMap = {
      "out-for-delivery": "Out For Delivery",
      "in-progress": "In Progress",
      "pending": "Pending",
      "delivered": "Delivered",
      "cancelled": "Cancelled",
      "dispatched": "Dispatched",
      "on-the-way": "On the way",
      "arrived": "Arrived"
      // Add more mappings as needed
    };
  
    return statusMap[status] || capitalize(status || "Unknown");
  };
