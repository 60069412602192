import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../services/categoryManagementApi";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { toast } from "react-toastify";
import moment from "moment";

const { timeSlotManagementApi } = ApiService;

const timeSlots = [
  "12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", 
  "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM",
  "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM",
  "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
  "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", 
  "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM",
  "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM",
  "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM",
  // Add more time slots as needed
];

const AddTimeSlot = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await timeSlotManagementApi.getById({ id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {    
      const currentDate = new Date('2025-01-27');
    
      const convertTo24Hour = (time) => {
        const [hourMinute, period] = time.split(' ');
        let [hours, minutes] = hourMinute.split(':').map(Number);
        if (period === 'PM' && hours !== 12) {
          hours += 12;
        }
        if (period === 'AM' && hours === 12) {
          hours = 0; 
        }
        return { hours, minutes };
      };
    
      const { hours: startHours, minutes: startMinutes } = convertTo24Hour(values.startTime);
      const { hours: endHours, minutes: endMinutes } = convertTo24Hour(values.endTime);
    
      const startTime = new Date(currentDate);
      startTime.setHours(startHours, startMinutes, 0, 0);
    
      const endTime = new Date(currentDate);
      endTime.setHours(endHours, endMinutes, 0, 0); 
    
      const payload = {
        ...(id ? { id } : {}),
        startTime: startTime.toISOString(), 
        endTime: endTime.toISOString(),
      };
    
      const res = id
        ? await timeSlotManagementApi.updateTimeSlot(payload)
        : await timeSlotManagementApi.add(payload);
    
    
      if (res.status === 200) {
        toast.success(res?.data?.message);
      }
    
      navigate(-1);
    } catch (error) {
      toast.error(error?.response?.data?.message, { toastId: "error" });
    } finally {
      setSubmitting(false);
    }
  };
  
  

  const getAvailableEndTimes = (startTime) => {
    const startIndex = timeSlots.indexOf(startTime);
    return timeSlots.slice(startIndex + 1, startIndex + 7);
  };

  return (
    <CardLayout>
      <Box >
        <Card>
          <CardHeader
            title={
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ color: "#333" }}
              >
                {id ? "Edit Time Slot" : "Add Time Slot"}
              </Typography>
            }
          />
          <CardContent>
            <Typography
              variant="body1"
              sx={{ mb: 3, color: "#666" }}
            >
              Select a start time and an end time for your time slot.
            </Typography>
            <Formik
              enableReinitialize
              initialValues={{
                startTime: moment(data?.startTime).format("hh:mm A") || "",
                endTime: moment(data?.endTime).format("hh:mm A") || "",
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    {console.log("values.startTime",values)}
                    {/* Start Time */}
                    <Grid item xs={6}>
                      <TextField
                        label="Start Time"
                        select
                        fullWidth
                        value={values.startTime}
                        onChange={(e) => {
                          const selectedStartTime = e.target.value;
                          setFieldValue("startTime", selectedStartTime);
                          setFieldValue("endTime", ""); 
                        }}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                          },
                        }}
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{
                          shrink: true, 
                        }}
                      >
                        <option value="" disabled>Select Start Time</option>
                        {timeSlots.map((time) => (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/* End Time */}
                    <Grid item xs={6}>
                      <TextField
                        label="End Time"
                        select
                        fullWidth
                        value={values.endTime}
                        onChange={(e) => setFieldValue("endTime", e.target.value)}
                        required
                        disabled={!values.startTime}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                          },
                        }}
                        SelectProps={{
                          native: true,
                        }}
                        InputLabelProps={{
                          shrink: true, 
                        }}
                      >
                        <option value="" disabled>Select End Time</option>
                        {values.startTime && 
                          getAvailableEndTimes(values.startTime).map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))
                        }
                      </TextField>
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderRadius: 2,
                          padding: "10px",
                          "&:hover": {
                            background: "linear-gradient(45deg, #2575fc, #6a11cb)",
                          },
                        }}
                      >
                        {id ? "Update Time Slot" : "Add Time Slot"}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </CardLayout>
  );
};

export default AddTimeSlot;
