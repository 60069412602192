import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Stack,
  Box,
  Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import ApiService from "../../services/categoryManagementApi";

const { emailTemplateApi } = ApiService;

const ViewEmail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await emailTemplateApi.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>Email Template</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Email Template View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Stack spacing={2}>
                    {/* Title */}
                    <Typography variant="h6">Title</Typography>
                    <Typography variant="body1">
                      {data?.title || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Slug */}
                    <Typography variant="h6">Slug</Typography>
                    <Typography variant="body1">
                      {data?.slug || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Subject */}
                    <Typography variant="h6">Subject</Typography>
                    <Typography variant="body1">
                      {data?.subject || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Message */}
                    <Typography variant="h6">Message</Typography>
                    <Box sx={{ overflowWrap: "break-word" }}>
                      {data?.content ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </Box>
                    <Divider />

                    {/* Created By */}
                    <Typography variant="h6">Created By</Typography>
                    <Typography variant="body1">
                      {data?.createdBy?.name || "N/A"}
                    </Typography>
                    <Divider />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default ViewEmail;