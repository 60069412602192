import React, { useEffect, useState } from "react";
import { 
  Grid, 
  Card, 
  Typography, 
  CardHeader, 
  CardContent, 
  Stack, 
  Box, 
  Divider, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { PictureAsPdf } from "@mui/icons-material";
import CardLayout from "../../layouts/CardLayout";
import ApiService from "../../services/categoryManagementApi";
import errorHandler from "../../utils/errorHandler";
import { IMAGE_BASE } from "../../utils/constants";
import { toast } from "react-toastify";

const { deliveryPartnerManagementApi } = ApiService;

const DeliveryPartnerManagementView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  const [isBlockDialogOpen, setIsBlockDialogOpen] = useState(false);
  const [blockReason, setBlockReason] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await deliveryPartnerManagementApi.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleBlockUser = async () => {
    if (!blockReason.trim() && data?.status !== 3) {
      toast.error("Reason is required to block the user.");
      return;
    }

    try {
      const payload = {
        id,
        reason: blockReason.trim(),
      };
      const response = data?.status === 3 
                      ? await deliveryPartnerManagementApi.changeStatus({id, status: 1})
                      : await deliveryPartnerManagementApi.blockDriver(payload)
      
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setIsBlockDialogOpen(false);
        setData({ ...data, status: data?.status === 3 ? 1 : 3 });
        if(data?.status === 3){
          setBlockReason("")
        }
      } else {
        toast.error(response?.data?.message || "Failed to block the user.");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Helmet>
        <title>Delivery Partner Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Delivery Partner View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Stack spacing={2}>
                    {/* Delivery Partner ID */}
                    <Typography variant="h6">Delivery Partner ID</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.deliveryPartnerId || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Name */}
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.name || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Contact Information */}
                    <Typography variant="h6">Contact Information</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      Email: {data?.email || "N/A"}
                    </Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      Mobile: {data?.phoneNumber || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Address */}
                    <Typography variant="h6">Address</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.address || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Average Ratings */}
                    <Typography variant="h6">Average Ratings</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.averageRatings || "No ratings available"}
                    </Typography>
                    <Divider />

                    {/* Performance Metrics */}
                    <Typography variant="h6">Performance Metrics</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      Orders Delivered: {data?.ordersDelivered || "N/A"}
                    </Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      Customer Ratings: {data?.customerRatings || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Status */}
                    <Typography variant="h6">Status</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.status === 1
                        ? "Active"
                        : data?.status === 0
                        ? "Inactive"
                        : "Blocked"}
                    </Typography>
                    <Divider />
                    
                    {/* Dealer Information */}
                    <Typography variant="h6">Dealer Information</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      Name : {data?.dealerId?.businessName || "N/A"}
                    </Typography>
                    <Divider />

                    {/* Documents */}
                    {data?.drivingLicense && (
                      <>
                        <Typography variant="h6">Driving License</Typography>
                        <Stack direction="row" spacing={2}>
                          
                            <Box>
                              <Link
                                to={IMAGE_BASE + data?.drivingLicense}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none", color: "inherit" }}
                              >
                                <PictureAsPdf fontSize="large" sx={{ color: "red" }} />
                                
                              </Link>
                            </Box>
                          
                        </Stack>
                        <Divider />
                      </>
                    )}
                    {data?.idProof && (
                      <>
                        <Typography variant="h6">ID Proof</Typography>
                        <Stack direction="row" spacing={2}>
                          
                            <Box>
                              <Link
                                to={IMAGE_BASE + data?.idProof}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none", color: "inherit" }}
                              >
                                <PictureAsPdf fontSize="large" sx={{ color: "red" }} />
                                
                              </Link>
                            </Box>
                          
                        </Stack>
                        <Divider />
                      </>
                    )}
                    
                      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                        <Button
                          variant="contained"
                          onClick={() => setIsBlockDialogOpen(true)}
                        >
                          {data?.status !== 3 ? "Block Driver" : "Unblock Driver"}
                        </Button>
                      </Box>
                  
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>

      <Dialog open={isBlockDialogOpen} onClose={() => setIsBlockDialogOpen(false)}>
        <DialogTitle>{data?.status === 3 ? "Unblock Driver" : "Block Driver"}</DialogTitle>
        <DialogContent>
          <Typography>
            {data?.status === 3 ? "Are you sure you want to unblock this driver?" 
            : "Please provide a reason for blocking this driver:"}
            </Typography>
          {data?.status !== 3 &&
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            value={blockReason}
            onChange={(e) => setBlockReason(e.target.value)}
            placeholder="Enter reason"
            multiline
            rows={3}
          />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBlockDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleBlockUser} color="error" variant="contained">
            {data?.status === 3 ? "Unblock" : "Block"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeliveryPartnerManagementView;
