import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import ApiService from "../../services/categoryManagementApi";

const { categoryManagementApi } = ApiService;

export default function CategoryManagement() {
  const navigate = useNavigate();

  const [searchItem, setSearchItem] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState([]);

  const handleClose = () => setShow(false);

  const columns = [
    {
      name: "S.No.",
      sortable: false,
      cell: (row, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => capitalize(row.name),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/category-management/view/${row?._id}`);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/category-management/edit/${row?._id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton color="primary" onClick={() => deleteData(row?._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const fetchProductData = () => {
    const body = {
      searchItem: searchItem ? searchItem.trim() : "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: status,
    };

    categoryManagementApi
      .getAll(body)
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data?.result || []);
          setTotalRecords(res?.data?.data?.totalRecords || 0);
        }
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  useEffect(() => {
    fetchProductData();
  }, [searchItem, currentPage, pageSize, refresh, status]);

  const deleteData = async (id) => {
    const category = data.find((item) => item._id === id);
    
    if (category && category?.products > 0) {
      toast.error("This category contains products. To delete this category, please remove all products from it or assign them to another category.", {
        toastId: "error",
      });
      return;
    }
  
    Swal.fire({
      text: "Are you sure you want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a1da",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await categoryManagementApi.deleteCategory({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
  
            const updatedData = data.filter((item) => item._id !== id);
            setData(updatedData);
            setTotalRecords((prevTotal) => prevTotal - 1);
  
            if (updatedData.length === 0 && currentPage > 1) {
              setCurrentPage((prevPage) => prevPage - 1);
            } else {
              fetchProductData();
            }
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          errorHandler(error);
        }
      }
    });
  };
  

  return (
    <Card>
      <Helmet>
        <title>Category Management</title>
      </Helmet>
      <CardHeader title="Category Management" titleTypographyProps={{ variant: "h4" }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Input
          placeholder="Search"
          value={searchItem}
          onChange={(e) => setSearchItem(e.target.value.trimStart())}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchItem("")}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          size="large"
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/category-management/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
         customStyles={{
          headCells: {
            style: {
              fontSize: "15px",
              fontWeight: "bold",
            },
          },
        }}
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalRecords}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPage={pageSize}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(1);
          }}
        />
      </CardContent>
    </Card>
  );
}