import { Grid, CardHeader, CardContent, Box, TextField, Button, Typography, Autocomplete } from "@mui/material";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import CardLayout from "../../layouts/CardLayout";
import ApiService from "../../services/categoryManagementApi";
import errorHandler from "../../utils/errorHandler";
import { IMAGE_BASE } from "../../utils/constants";
import { driverSchema } from "../../utils/Schema";
import { Cancel } from "@mui/icons-material";
const { deliveryPartnerManagementApi } = ApiService;

function AddDeliveryPartner() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedLicense, setSelectedLicense] = useState([]);
  const [selectedIdProof, setSelectedIdProof] = useState([]);
  const licenseInputRef = useRef(null);
  const idProofInputRef = useRef(null);
  const [data, setData] = useState(null);
  const [dealers, setDealers] = useState([]);

  const fetchDealers = useCallback(async (searchItem = "") => {
    try {
      const payload = {
        searchItem, // Include the search term
        status: [],
      };
      const response = await deliveryPartnerManagementApi.getAllDealersDropDown(payload);
      setDealers(response?.data?.data?.result || []);
    } catch (error) {
      errorHandler(error);
    }
  }, []);

  useEffect(() => {
    fetchDealers();
  }, [fetchDealers]);

  const debounce = useCallback((func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  }, []);
  
  const handleDealerSearch = debounce((value) => {
    fetchDealers(value);
  }, 300);

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await deliveryPartnerManagementApi.getById({ id });
        const resData = response?.data?.data;
        console.log("resData",resData);
        setSelectedLicense([{ name: "Driving License", path: IMAGE_BASE + resData?.drivingLicense }]);
        setSelectedIdProof([{ name: "ID Proof", path: IMAGE_BASE + resData?.idProof }]);
        setImagePreview(IMAGE_BASE + resData?.image);
        setData(resData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);
  console.log("selectedLicense",selectedIdProof);

  const formik = useFormik({
    enableReinitialize: id ? true : false,
    initialValues: {
      name: data?.name || "",
      email: data?.email || "",
      phoneNumber: data?.phoneNumber || "",
      address: data?.address || "",
      dealerId: data?.dealerId?._id || "",
      vehicleRegistration: data?.vehicleNumber || "",
      drivingLicense: selectedLicense.length ? selectedLicense[0]?.file || selectedLicense[0]?.path : "",
      idProof: selectedIdProof.length ? selectedIdProof[0]?.file || selectedIdProof[0]?.path : "",
    },
    validationSchema: driverSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        if (id) formData.append("id", id);
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("phoneNumber", values.phoneNumber);
        formData.append("dealerId", values.dealerId);
        formData.append("imagePath", "driver");
        formData.append("address", values.address);
        formData.append("vehicleRegistration", values.vehicleRegistration);
        formData.append("drivingLicense", values.drivingLicense);
        formData.append("idProof", values?.idProof);
        

        const response = id
          ? await deliveryPartnerManagementApi.updateDriver(formData)
          : await deliveryPartnerManagementApi.add(formData);

        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleFileChange = (event, type) => {
    const file = event.currentTarget.files[0];
  
    if (file?.type !== "application/pdf") {
      toast.error("Only PDF files are allowed.");
      return;
    }
  
    const fileObject = { name: file.name, file, preview: URL.createObjectURL(file) };
  
    if (type === "license") {
      setSelectedLicense([fileObject]);
      formik.setFieldValue("drivingLicense", file);
      if (licenseInputRef.current) licenseInputRef.current.value = "";
    } else if (type === "idProof") {
      setSelectedIdProof([fileObject]);
      formik.setFieldValue("idProof", file);
      if (idProofInputRef.current) idProofInputRef.current.value = "";
    }
  };
  

  const handleDeleteFile = (type) => {
    if (type === "license") {
      setSelectedLicense([]);
    } else if (type === "idProof") {
      setSelectedIdProof([]);
    }
  };
  

  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Delivery Partner</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Delivery Partner`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    required
                    type="text"
                    value={formik.values.phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,10}$/.test(value)) {
                        formik.setFieldValue("phoneNumber", value);
                      }
                    }}
                    name="phoneNumber"
                    error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    required
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                <Autocomplete
                  options={dealers}
                  getOptionLabel={(option) => option.businessName || ""}
                  value={dealers.find((dealer) => dealer._id === formik.values.dealerId) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("dealerId", newValue?._id || null);
                  }}
                  onInputChange={(event, value) => {
                    handleDealerSearch(value); 
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}> 
                      {option.businessName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Dealer"
                      placeholder="Search Dealers"
                      required
                      error={Boolean(formik.touched.dealerId && formik.errors.dealerId)}
                      helperText={formik.touched.dealerId && formik.errors.dealerId}
                    />
                  )}
                />


                </Grid>
              
                <Grid item xs={6} mt={2}>
                  <Box display="flex" alignItems="center">
                    <Button variant="outlined" component="label">
                      Upload License
                      <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={(e) => handleFileChange(e, "license")}
                        ref={licenseInputRef}
                      />
                    </Button>
                    <Typography variant="body2" sx={{ ml: 2 }}>
                    {selectedLicense.length > 0
                      ? `${selectedLicense.length} File${selectedLicense.length > 1 ? "s" : ""} Selected`
                      : "No file selected"}
                    </Typography>
                    </Box>
                    {console.log("formik",formik)}
                    {formik.touched.drivingLicense && formik.errors.drivingLicense && (
                      <Typography variant="body2" color="error" sx={{ fontSize: '12px', marginLeft: '10px' }}>
                        {formik.errors.drivingLicense}
                      </Typography>
                    )}
                    {selectedLicense.length > 0 && (
                  <Box mt={2}>
                    {selectedLicense.map((document, index) => (
                      <Box key={index} position="relative" display="inline-block" mr={2} mb={2}>
                        <Link
                          to={document.preview || document.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/pdf.png"
                            alt="PDF Preview"
                            width={80}
                            height={80}
                          />
                        </Link>
                        <Button
                          variant="text"
                          color="error"
                          className="deletepdficon"
                          onClick={() => handleDeleteFile("license")}
                          startIcon={<Cancel />}
                          sx={{
                            position: "absolute",
                            left: "49px",
                            top: "-15px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            borderRadius: "50%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
                  
                </Grid>
                <Grid item xs={6} mt={2}>
                  <Box display="flex" alignItems="center">
                    <Button variant="outlined" component="label">
                      Upload ID Proof
                      <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={(e) => handleFileChange(e, "idProof")}
                        ref={idProofInputRef}
                      />
                    </Button>
                    <Typography variant="body2" sx={{ ml: 2 }}>
                    {selectedIdProof.length > 0
                      ? `${selectedIdProof.length} File Selected`
                      : "No file selected"}
                  </Typography>
                  </Box>
                  {formik.touched.idProof && formik.errors.idProof && (
                    <Typography variant="body2" color="error" sx={{ fontSize: '12px', marginLeft: '10px' }}>
                      {formik.errors.idProof}
                    </Typography>
                  )}
                  {selectedIdProof.length > 0 && (
                  <Box mt={2}>
                    {selectedIdProof.map((document, index) => (
                      <Box key={index} position="relative" display="inline-block" mr={2} mb={2}>
                        <Link
                          to={document.preview || document.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/pdf.png"
                            alt="PDF Preview"
                            width={80}
                            height={80}
                          />
                        </Link>
                        <Button
                          variant="text"
                          color="error"
                          className="deletepdficon"
                          onClick={() => handleDeleteFile("idProof")}
                          startIcon={<Cancel />}
                          sx={{
                            position: "absolute",
                            left: "49px",
                            top: "-15px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            borderRadius: "50%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
                  
                </Grid>
                <Grid item xs={12} mt={4}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    {id ? "Edit" : "Create"} Delivery Partner
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddDeliveryPartner;
