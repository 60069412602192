export const API_BASE   = "https://gasdelivery-api.devtechnosys.tech/admin";
export const IMAGE_BASE = 'https://gasdelivery-api.devtechnosys.tech/';

//---------------------karan---------------------------------------------------
// export const API_BASE = "http://172.16.11.135:3002/admin"
// export const IMAGE_BASE = 'http://172.16.11.135:3002/';


export const BASE_API = API_BASE + "/api";
export const MAIN_URL = BASE_API + "/api/admin";

export const MOBILE_REGEX = /^[0-9]{10}$/;

export const PASSWORDS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?])[A-Za-z\d#$@!%^&*?]{8,}$/;

export const EMAIL_REGEX = /^[a-zA-Z][a-zA-Z0-9._]*[a-zA-Z0-9]@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
// export const NOSPACE_REGEX = /^[^\s]+(?:\s.*)?$/; 
export const NOSPACE_REGEX = /^[^\s](?:.*[^\s])?$/; 
// export const NOSPACE_REGEX = /^[^\s]+( [^\s]+)?$/; 
export const NOSPACE = "Spaces are not allowed at the start or end of the text.";

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/x-ms-wmv",
  "video/quicktime",
  "video/webm",
  "application/pdf",
  "text/plain",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/;

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const REMOVEHTML = /(<([^>]+)>)/gi;

export const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Prefer not to say", label: "Prefer not to say" },
];

export const STATUS_OPTIONS = [
  { value: false, label: "Active" },
  { value: true, label: "Inactive" },
];

export const STATUS_OPTIONS_LIST = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
export const Verification_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "cancelled", label: "Cancelled" },
];
export const PLAN_OPTIONS = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

export const ORDER_STATUS_OPTIONS_LIST = [
  { label: "Pending", value: "pending" },
  { label: "In-Progress", value: "in-progress" },
  { label: "Dispatched", value: "dispatched" },
  { label: "Out For Delivery", value: "out-for-delivery" },
  { label: "On the Way", value: "on-the-way" },
  { label: "Arrived", value: "arrived-at-location" },
  { label: "Delivered", value: "delivered" },
  { label: "Cancelled", value: "cancelled" },
];

export const PAYMENTSTATUS_OPTIONS_LIST = [
  { value: "initiated", label: "Initiated" },
  { value: "success", label: "Success" },
  { value: "pending", label: "Pending" },
  { value: "failed", label: "Failed" },
  { value: "refund", label: "Refund" },
];
export const DELIVERY_STATUS_OPTIONS = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
  { value: 3, label: "Block" },
];
