import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  Switch,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import ApiService from "../../services/categoryManagementApi";
import { STATUS_OPTIONS_LIST } from "../../utils/constants";
import TableFilter from "../../components/shared/TableFilter";
import { fDateTime } from "../../utils/formatTime";

const { emailTemplateApi } = ApiService;

export default function EmailTemplateList() {
  const navigate = useNavigate();

  const [searchItem, setSearchItem] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState([]);

  const handleClose = () => setShow(false);

  const columns = [
    {
      name: "S.No.",
      sortable: false,
      cell: (row, index) => (currentPage - 1) * pageSize + index + 1,
      width: "10%",
    },
    {
      name: "Title",
      sortable: true,
      selector: (row) => capitalize(row.title),
      width: "15%",
    },
    {
      name: "Subject",
      sortable: true,
      selector: (row) => capitalize(row.subject),
      width: "15%",
    },
    {
      name: "Created By",
      sortable: true,
      selector: (row) => capitalize(row?.createdBy?.name),
      width: "15%",
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => fDateTime(row.createdAt),
      width: "15%",
    },
    {
      name: "Status",
      width: "15%",
      selector: (row) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={row.status === 1}
                onClick={() => {
                  setShow(true);
                  setCurrentStatus(row.status);
                  setStatusId(row._id);
                  setAction("status");
                  setWarning(
                    `Are you sure you want to ${
                      row.status === 1 ? "deactivate" : "activate"
                    } this email ?`
                  );
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/email-template/view/${row?._id}`);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/email-template/edit/${row?._id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton color="primary" onClick={() => deleteData(row?._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
      // Add style for right-alignment here instead of using the "right" prop.
      style: { textAlign: "right" },
    },
  ];

  const fetchProductData = () => {
    const body = {
      searchItem: searchItem ? searchItem.trim() : "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: status,
    };

    emailTemplateApi
      .getAll(body)
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data?.result || []);
          setTotalRecords(res?.data?.data?.totalRecords || 0);
        }
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  useEffect(() => {
    fetchProductData();
  }, [searchItem, currentPage, pageSize, refresh, status]);

  const deleteData = async (id) => {
    Swal.fire({
      text: "Are you sure you want to delete this email?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a1da",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await emailTemplateApi.delete({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
            setRefresh(!refresh);
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          errorHandler(error);
        }
      }
    });
  };

  const toggleChecked = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus ? 0 : 1;
      const res = await emailTemplateApi.changeStatus({
        id,
        status: newStatus,
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message, { toastId: "success" });
        setRefresh(!refresh);
        setShow(false);
      } else {
        toast.error(res?.data?.message, { toastId: "error" });
        setShow(false);
      }
    } catch (err) {
      setShow(false);
      errorHandler(err);
    }
  };

  return (
    <Card>
      <Helmet>
        <title>Email Template</title>
      </Helmet>
      <CardHeader
        title="Email Template"
        titleTypographyProps={{ variant: "h4" }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <TableFilter
          data={STATUS_OPTIONS_LIST}
          value={status}
          setValue={setStatus}
          filterName="Status"
        />
        <Input
          placeholder="Search"
          value={searchItem}
          onChange={(e) => setSearchItem(e.target.value.trimStart())}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchItem("")}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
          sx={{ height: "40px", ml: 2 }}
        />
        <Button
          size="large"
          variant="contained"
          sx={{ height: "40px", ml: 3, mr: 3 }}
          onClick={() => navigate("/email-template/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
          customStyles={{
            headCells: {
              style: {
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
          }}
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalRecords}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPage={pageSize}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(1);
          }}
        />
      </CardContent>
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>{warning}</DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => toggleChecked(statusId, currentStatus)}
          >
            Yes
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}