import React, { useEffect, useState } from "react";
import { 
  Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, 
  FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { EditOutlined, VerifiedTwoTone } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import ApiService from "../../services/categoryManagementApi";
import { fDateTime } from "../../utils/formatTime";
import TableFilter from "../../components/shared/TableFilter";
import { DELIVERY_STATUS_OPTIONS, STATUS_OPTIONS_LIST } from "../../utils/constants";
import BlockIcon from '@mui/icons-material/Block';
const { deliveryPartnerManagementApi } = ApiService;

export default function DeliveryPartnerManagement() {
  const navigate = useNavigate();

  // State variables
  const [searchItem, setSearchItem] = useState("");
  const [totalPages, setTotalPages] = useState(0); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]); 
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [refresh, setRefresh] = useState(false); 
  const [status, setStatus] = useState([]);

  // Dialog handlers
  const handleClose = () => setShow(false);

  // Table column definitions
  const columns = [
    {
      name: "S.No.",
      sortable: false,
      cell: (row, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => capitalize(row.name),
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => capitalize(row.email),
    },
    {
      name: "Driver ID",
      sortable: true,
      selector: (row) => row.deliveryPartnerId,
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => fDateTime(row.createdAt),
    },
    {
      name: "Status",
      cell: (row) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
              disabled={row?.status == 3}
                checked={row.status === 1}
                onClick={() => {
                  setShow(true);
                  setCurrentStatus(row.status);
                  setStatusId(row._id);
                  setAction("status");
                  setWarning(
                    `Are you sure you want to ${
                      row.status === 1 ? "deactivate" : "activate"
                    } the dealer?`
                  );
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
      ),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          {row?.status == 3 &&
            <Tooltip title="Block" placement="right">
              <IconButton
                color="primary"
                onClick={() => {
                  setStatusId(row._id);
                }}
              >
                <BlockIcon />
              </IconButton>
            </Tooltip>
          }
          
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => navigate(`/delivery-partner-management/view/${row?._id}`)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => navigate(`/delivery-partner-management/edit/${row?._id}`)}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton
              color="primary"
              onClick={() => deleteData(row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  // Fetch dealer data
  useEffect(() => {
    const body = {
      searchItem: searchItem || "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: status,
    };

    deliveryPartnerManagementApi
      .getAll(body)
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data?.result);
          setTotalPages(res?.data?.data?.totalRecords);
        }
      })
      .catch((err) => errorHandler(err));
  }, [searchItem, currentPage, pageSize, refresh, status]);

  const handlePageChange = (page) => setCurrentPage(page);

  const deleteData = async (id) => {
    Swal.fire({
      text: "Are you sure you want to delete the delivery partner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a1da",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Reason for Deletion",
          input: "textarea",
          inputPlaceholder: "Enter the reason for deleting this driver...",
          inputAttributes: {
            "aria-label": "Enter the reason for deletion",
          },
          showCancelButton: true,
          confirmButtonColor: "#00a1da",
          confirmButtonText: "Submit",
          cancelButtonText: "Cancel",
          preConfirm: (reason) => {
            if (!reason) {
              Swal.showValidationMessage("Reason is required to proceed.");
            }
            return reason;
          },
        }).then(async (reasonResult) => {
          if (reasonResult.isConfirmed) {
            try {
              const res = await deliveryPartnerManagementApi.deleteDriver({
                id,
                reason: reasonResult.value,
              });
              if (res?.status === 200) {
                toast.success(res?.data?.message);
                setRefresh(!refresh);
              } else {
                toast.error(res?.data?.message);
              }
            } catch (error) {
              errorHandler(error);
            }
          }
        });
      }
    });
  };
  

  const toggleChecked = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus ? 0 : 1; 

      const res = await deliveryPartnerManagementApi.changeStatus({
        id,
        status: newStatus,
      });

      setCurrentStatus("");

      if (res.status === 200 && res?.data?.message) {
        toast.success(res?.data?.message, { toastId: "error1" });
        setRefresh(!refresh);
        setShow(false);
      } else {
        toast.error(res?.data?.message, { toastId: "error1" });
        setShow(false);
      }
    } catch (err) {
      setShow(false);
      errorHandler(err);
    }
  };
  

  return (
    <Card>
      <Helmet>
        <title>Delivery Partner Management</title>
      </Helmet>
      <CardHeader title="Delivery Partner Management" />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TableFilter
          data={DELIVERY_STATUS_OPTIONS}
          value={status}
          setValue={setStatus}
          filterName="Status"
        />
        <Input
          value={searchItem}
          placeholder="Search"
          onChange={(e) => setSearchItem(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchItem("")}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/delivery-partner-management/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
        customStyles={{
          headCells: {
            style: {
              fontSize: "15px",
              fontWeight: "bold",
            },
          },
        }}
          columns={columns}
          data={data || []}
          pagination
          paginationServer
          paginationTotalRows={totalPages}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          paginationRowsPerPageOptions={[2,5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(1); // Reset to first page
          }}
        />
      </CardContent>


      <Dialog
        open={show}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle as="h2">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              if (action === "status") {
                toggleChecked(statusId, currentStatus); // Pass the id and current status
              }
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      
    </Card>
  );
}
