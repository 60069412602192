import AxiosInterceptor from "../utils/AxiosInterceptor";

const categoryManagementApi = {
  add: (value) => AxiosInterceptor().post('/addCategory', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllCategories`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneCategory/${values.id}`),
  updateCategory: (values) => AxiosInterceptor().post(`/updateCategory`, values),
  deleteCategory: (values) => AxiosInterceptor().get(`/deletecategory/${values.id}`, ),
};

const productManagementApi = {
  add: (value) => AxiosInterceptor().post('/addProduct', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllProducts`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneProduct/${values.id}`),
  updateProduct: (values) => AxiosInterceptor().post(`/updateProduct`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusProduct`, values),
  deleteProduct: (values) => AxiosInterceptor().get(`/deleteProduct/${values.id}`, ),
  getAllCategoryForProducts: (values) => AxiosInterceptor().post('/getAllDropdownCategory', values)
};

const dealerManagementApi = {
  add: (value) => AxiosInterceptor().post('/addDealer', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllDealers`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneDealer/${values.id}`),
  updateDealer: (values) => AxiosInterceptor().post(`/updateDealer`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusDealer`, values),
  deleteDealer: (values) => AxiosInterceptor().get(`/deleteDealer/${values.id}`, ),
  approveDealer: (values) => AxiosInterceptor().post(`/approveDealer`, values),
}

const customerManagementApi = {
  add: (value) => AxiosInterceptor().post('/addUser', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllUsers`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneUser/${values.id}`),
  updateUser: (values) => AxiosInterceptor().post(`/updateUser`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusUser`, values),
  deleteUser: (values) => AxiosInterceptor().get(`/deleteUser/${values.id}`, ),
}

const orderManagementApi = {
  getAll: (values) => AxiosInterceptor().post(`/getAllOrders`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneOrder/${values.id}`),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusOfOrder`, values),
};

const deliveryPartnerManagementApi = {
  add: (value) => AxiosInterceptor().post('/addDriver', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllDriver`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneDriver/${values.id}`),
  updateDriver: (values) => AxiosInterceptor().post(`/updateDriver`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeDriverStatus`, values),
  deleteDriver: (values) => AxiosInterceptor().post(`/deleteDriver`,values ),
  blockDriver: (values) => AxiosInterceptor().post(`/blockDriver`, values),
  getAllDealersDropDown: (values) => AxiosInterceptor().post('/getAllDealersDropDown', values),
}


const paymentApi = {
  add: (value) => AxiosInterceptor().post('/addContent', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllPayments`, values),
  getById: (values) => AxiosInterceptor().get(`/getOnePayment/${values.id}`, ),
  updateUser: (values) => AxiosInterceptor().post(`/changeStatusOfPayment`, values),
  refundAmount: (values) => AxiosInterceptor().post(`/refund`, values),
}

const cmsApi = {
  add: (value) => AxiosInterceptor().post('/addContent', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllContent`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneContent/${values.id}`, ),
  updateUser: (values) => AxiosInterceptor().post(`/updateContent`, values),
}

const timeSlotManagementApi = {
  add: (value) => AxiosInterceptor().post('/addTimeSlot', value),
  getAll: (values) => AxiosInterceptor().post(`/getAllTimeSlot`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneTimeSlot/${values.id}`),
  updateTimeSlot: (values) => AxiosInterceptor().post(`/updateTimeSlot`, values),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatus`, values),
  deleteTimeSlot: (values) => AxiosInterceptor().get(`/deleteTimeSlot/${values.id}`),
}


const emailTemplateApi = {
  getAll: (values) => AxiosInterceptor().post(`/getAllTemplate`, values),
  getById: (values) => AxiosInterceptor().get(`/getOneTemplate/${values.id}`),
  changeStatus: (values) => AxiosInterceptor().post(`/changeStatusEmailTemplate`, values),
  update: (values) => AxiosInterceptor().post(`/updateTemplate`, values),
  add: (values) => AxiosInterceptor().post(`/addTemplate`, values),
  delete: (values) => AxiosInterceptor().get(`/deleteTemplate/${values.id}`),
};


export default {categoryManagementApi, productManagementApi, dealerManagementApi, customerManagementApi, cmsApi, emailTemplateApi, orderManagementApi,timeSlotManagementApi, deliveryPartnerManagementApi, paymentApi};
