import { Grid, CardHeader, CardContent,  Box, TextField,  CircularProgress, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Checkbox, ListItemText, Avatar, Button } from "@mui/material";
import apiOfferbannerManagement from "../../services/offerandbannerservices";
import React, { useState, useRef, useEffect , useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import errorHandler from "../../utils/errorHandler";
import { IMAGE_BASE } from "../../utils/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import CardLayout from "../../layouts/CardLayout";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { bannerAndOffer } from "../../utils/Schema";
import FieldErrorMessage from "../../components/FieldErrorMessage";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { capitalizeFirstLetter } from "../../utils/helpers";

function AddbannerOffer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [products, setProducts] = useState([]);
  const fileInputRef = useRef(null);
  const [productList, setProductList] = useState([]);
  const [freeProductList, setFreeProductList] = useState([]);
  const [data , setData] = useState();
  
  const offerTypes = process.env.REACT_APP_OFFER_TYPES
    ? process.env.REACT_APP_OFFER_TYPES.split(",")
    : ["banner", "offer"];

  const couponTypes = process.env.REACT_APP_COUPON_TYPES
    ? process.env.REACT_APP_COUPON_TYPES.split(",")
    : ["B1G1", "B1GO", "percentage"];

  const { id } = useParams();

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await apiOfferbannerManagement.getById({ id });
        const resData = response?.data?.data;
        setImagePreview(IMAGE_BASE + resData?.image)
        setFreeProductList([resData?.freeProductId?._id])

        resData?.purchaseProductIds?.map((product) => (
          setProductList((prevProductList) => [...prevProductList, product?._id])
        ));
        
        setData(resData)
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);    

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,      
    initialValues: {
      name               : data?.name ? data?.name : "",
      description        : data?.description || "",
      startDate          : data?.startDate ? data?.startDate : "",
      imagePath          : "banner",
      endDate            : data?.endDate ? data?.endDate : "",
      offerType          : data?.offerType==="banner" ? offerTypes[0] : data?.offerType==="offer" ?offerTypes[1]: offerTypes[0],
      couponCode         : data?.couponCode ? data?.couponCode : "",
      minimumPrice       : data?.minimumPrice ? data?.minimumPrice : "",
      discount           : data?.discount ? data?.discount : "",
      couponType         : data?.couponType==="B1G1" ? "B1G1" : data?.couponType==="B1GO" ? "B1GO" : data?.couponType==="percentage" ? "percentage":"",
      freeProductId      : freeProductList || "",
      purchaseProductIds : productList || [],
      image              : data?.image ? data?.image : "",
    },
    validationSchema: bannerAndOffer(id),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        
        if(id){
          formData.append("id", id);
        }


        if (values.offerType === 'offer') {
          // formData.append(`couponType`, values.couponType);
          if (values.couponType === 'B1G1') {
            if (Array.isArray(values.purchaseProductIds)) {
              values.purchaseProductIds.forEach((id, index) => {
                formData.append(`purchaseProductIds[${index}]`, id);
              });
            }
          } else if (values.couponType === 'B1GO') {
            if (Array.isArray(values.purchaseProductIds)) {
              values.purchaseProductIds.forEach((id, index) => {
                formData.append(`purchaseProductIds[${index}]`, id);
              });
            }
            if (values.freeProductId) {
              formData.append("freeProductId", values.freeProductId);
            }
          } else {
            if (values.minimumPrice) {
              formData.append("minimumPrice", values.minimumPrice);
            }
            if (values.discount) {
              formData.append("discount", values.discount);
            }
          }
        }
 
        Object.keys(values).forEach((key) => {
          if (key !== 'image') {
            if (values.offerType === 'banner' && (key === 'couponCode' || key === 'couponType' ||  key === 'minimumPrice')) {return; }
            if (values.couponType === 'percentage' && (key === 'minimumPrice' || key === 'discount' || key === 'purchaseProductIds' || key === 'freeProductId') ) { return;  }
            if ((values.couponType === 'B1G1') &&(key === 'purchaseProductIds' || key === 'freeProductId' || key === 'minimumPrice')  ) { return;  }
            if ((values.couponType === 'B1GO') &&(key === 'purchaseProductIds' || key === 'freeProductId')  ) { return;  }

            formData.append(key, values[key]);
          }
        });
        
        if (selectedImage) {
          formData.append("image", selectedImage);
        }
        const response = id
        ? await apiOfferbannerManagement.updateBanner(formData)
        : await apiOfferbannerManagement.add(formData);

        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);
        setLoading(false);
    
        fileInputRef.current.value = "";
        setSelectedImage(null);
        setImagePreview(null);
      } catch (error) {
        console.log("err",error);
        errorHandler(error);
        setLoading(false);
      }
    },
  });


  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    const validTypes = ["image/jpg", "image/png", "image/jpeg"];

    if (selectedImage && selectedImage.name === file?.name) {
      toast.error("You cannot upload the same image again.");
      fileInputRef.current.value = "";
      return;
    }

    if (file && validTypes.includes(file.type)) {
      setImageLoading(true);
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("image", file);
      setTimeout(() => {
        setImageLoading(false);
      }, 2000);
    } else {
      toast.error("Only png, jpg, jpeg allowed.).");
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await apiOfferbannerManagement.getProductList();
        if (res.status === 200) {
          setProducts(res.data.data);
        }
      } catch (err) {
        console.log(err);
        errorHandler(err);
      }
    };
    fetchProducts();
  }, []);

  const handleChange = (event) => {
    const {target: { value },} = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    formik.setFieldValue('purchaseProductIds', selectedValues);
  };

  const handleChangeFreeProduct = (event) => {
    const { target: { value },} = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    formik.setFieldValue('freeProductId', selectedValues);
  };

  const isToday = (date) => dayjs().isSame(date, 'day');
  const getMinDateTime = () => {
    const now = dayjs();
    return isToday(now) ? now : now.startOf('day');
  };

  return (
    <Box>
      <Helmet>
        <title>{id ? 'Edit' : 'Add'} Offer and Banner</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? 'Edit' : 'Add'} Offer and Banner`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  {/* Hidden File Input */}
                  <input
                    accept="image/*"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  {/* Image Preview and Clickable Upload Box */}
                  <Box
                    mt={2}
                    onClick={() => fileInputRef.current.click()}
                    className="upload-box"
                  >
                    {imageLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Avatar
                        src={imagePreview || "/path/to/default-avatar.jpg"}
                        alt="preview"
                        className="avatar"
                      />
                    )}
                  </Box>

                  {/* Centered Upload Button */}
                  <Box mt={2} display="flex" justifyContent="center">
                  <Button
                        size="large"
                        variant="contained"
                        sx={{ ml: 3, mr: 3 }}
                        onClick={() => fileInputRef.current.click()}
                      >
                          {id ? "Edit" : imagePreview ? "Change" : "Upload"} Image
                      </Button>
                
                  </Box>

                  {/* Error Message */}
                  {formik.touched.image && formik.errors.image ? (
                    <div className="imageerror">
                      {formik.touched.image && formik.errors.image}
                    </div>
                  ) : null}
                </Grid>
        
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    required
                    error={Boolean(formik.touched.description && formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <MobileDateTimePicker 
                        label="Start Date"
                        name="startDate"
                        disabled={Boolean(id)}
                        value={formik.values.startDate ? dayjs(formik.values.startDate) : null}
                        onChange={(event) => {
                          const value = event?.$d;
                          formik.setFieldValue("startDate", new Date(value));
                        }}
                        // error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}
                        minDateTime={getMinDateTime()} 
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FieldErrorMessage formik={formik} name="startDate" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <MobileDateTimePicker 
                        label="End Date"
                        name="endDate"
                        disabled={Boolean(id)}
                        value={formik?.values?.endDate ? dayjs(formik?.values?.endDate) : null}
                        onChange={(event) => {
                          const value = event?.$d;
                          formik.setFieldValue("endDate", new Date(value).toISOString());
                        }}
                        error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}
                        minDateTime={getMinDateTime()} 

                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FieldErrorMessage formik={formik} name="endDate" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="offer-type-label">Offer Type</InputLabel>
                    <Select
                      labelId="offer-type-label"
                      label="Offer Type"
                      value={formik.values.offerType}
                      onChange={formik.handleChange}
                      name="offerType"
                      error={Boolean(formik.touched.offerType && formik.errors.offerType)}
                      helperText={formik.touched.offerType && formik.errors.offerType}
                      InputLabelProps={{
                        shrink: true, 
                      }}
                    >
                      {offerTypes.map((type, index) => (
                        <MenuItem key={index} value={type}>{capitalizeFirstLetter(type)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FieldErrorMessage formik={formik} name="offerType" />
                </Grid>

                {/* Conditional Fields for Offer Type */}
                {formik.values.offerType === "offer" && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth required>
                        <InputLabel id="coupon-type-label">Coupon Type</InputLabel>
                        <Select
                          labelId="coupon-type-label"
                          label="Coupon Type"
                          value={formik.values.couponType}
                          onChange={formik.handleChange}
                          name="couponType"
                          error={Boolean(formik.touched.couponType && formik.errors.couponType)}
                          helperText={formik.touched.couponType && formik.errors.couponType}
                        >
                          {couponTypes.map((type, index) => (
                            <MenuItem key={index} value={type}>{capitalizeFirstLetter(type)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FieldErrorMessage formik={formik} name="couponType" />
                    </Grid>

                    {(formik.values.couponType === "B1G1" || formik.values.couponType === "B1GO") && (
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth required
                           error={Boolean(formik.touched.purchaseProductIds && formik.errors.purchaseProductIds)}
                        >
                          <InputLabel id="purchase-product-id-label">Purchase Product</InputLabel>
                          <Select
                            labelId="purchase-product-id-label"
                            label="Purchase Product"
                            id="demo-multiple-checkbox"
                            multiple
                            value={formik?.values?.purchaseProductIds}
                            name="purchaseProductIds"
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected?.map((id) => products.find(product => product._id === id)?.name).join(", ")}
                        
                        >
                            {products.map((product, index) => (
                              <MenuItem key={index} value={product._id} label={product.name}>
                                <Checkbox checked={formik?.values?.purchaseProductIds?.includes(product._id)} label={formik?.values?.purchaseProductIds?.includes(product.name)} />
                                <ListItemText primary={product.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FieldErrorMessage formik={formik} name="purchaseProductIds" />
                      </Grid>
                    )}

                    {formik.values.couponType === "B1GO" && (
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth required
                          error={Boolean(formik.touched.freeProductId && formik.errors.freeProductId)}
                        >
                          <InputLabel id="free-product-label">Free Product</InputLabel>
                          <Select
                            labelId="free-product-label"
                            label="Free Product"
                            id="demo-multiple-checkbox"
                            value={formik?.values?.freeProductId ? formik?.values?.freeProductId : ""}
                            name="freeProductId"
                            onChange={handleChangeFreeProduct}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.map((id) => products.find(product => product._id === id)?.name).join(", ")} // Display names in the select
                          >

                            {products.map((product, index) => (
                              <MenuItem key={index} value={product._id} label={product.name}>
                                <ListItemText primary={product.name} />                               
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FieldErrorMessage formik={formik} name="freeProductId" />
                      </Grid>
                    )}

                    {formik.values.couponType === "percentage" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Discount"
                            required
                            type="number"
                            value={formik.values.discount}
                            onChange={formik.handleChange}
                            name="discount"
                            error={Boolean(formik.touched.discount && formik.errors.discount)}
                            helperText={formik.touched.discount && formik.errors.discount}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Minimum Price"
                            required
                            type="number"
                            value={formik.values.minimumPrice}
                            onChange={formik.handleChange}
                            name="minimumPrice"
                            error={Boolean(formik.touched.minimumPrice && formik.errors.minimumPrice)}
                            helperText={formik.touched.minimumPrice && formik.errors.minimumPrice}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Coupon Code"
                        required
                        value={formik.values.couponCode}
                        onChange={(event) => {
                          const upperCaseValue = event.target.value.toUpperCase(); 
                          formik.setFieldValue('couponCode', upperCaseValue);
                        }}
                        name="couponCode"
                        error={Boolean(formik.touched.couponCode && formik.errors.couponCode)}
                        helperText={formik.touched.couponCode && formik.errors.couponCode}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Grid item xs={4}>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
                      {id ? 'Edit' : 'Add'}  {formik.values.offerType === "Offer" ?"Offer" :"banner"}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddbannerOffer;