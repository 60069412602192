import React, { useEffect, useState } from "react";
import { 
  Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, 
  FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import ApiService from "../../services/categoryManagementApi";
import { fDateTime } from "../../utils/formatTime";
import TableFilter from "../../components/shared/TableFilter";
import { ORDER_STATUS_OPTIONS_LIST, STATUS_OPTIONS_LIST } from "../../utils/constants";
import { formatOrderStatus } from "../../utils/CommonFunction";

const { orderManagementApi } = ApiService;


export default function OrderManagement() {
  const navigate = useNavigate();

  // State variables
  const [searchItem, setSearchItem] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]); 
  const [refresh, setRefresh] = useState(false); 
  const [status, setStatus] = useState([]);


  // Table column definitions
  const columns = [
    {
      name: "S.No.",
      sortable: false,
      cell: (row, index) => (currentPage - 1) * pageSize + index + 1,
      width: "15%",
    },
    {
      name: "Order ID",
      sortable: true,
      selector: (row) => capitalize(row.orderNumber),
      width: "15%",
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => capitalize(row.userName),
      width: "15%",
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => fDateTime(row.createdAt),
      width: "20%",
    },
    {
      name: "Order Status",
      sortable: true,
      // cell: (row) => <span>{formatOrderStatus(row?.orderStatus?.stage)}</span>,
      width: "15%",
      cell: (row) => (
        <select
          value={row?.orderStatus?.stage} 
          onChange={(e) => handleStatusChange(row._id, e.target.value)}
          disabled={row?.orderStatus?.stage.toLowerCase() === "delivered"}
          style={{
            padding: "6px 10px",
            border: "1px solid #ccc",
            background: "none",
            borderRadius: "4px",
            outline: "none",
          }}
        >
          {ORDER_STATUS_OPTIONS_LIST.map((status) => (
            <option key={status.value} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
      ),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <div style={{ marginRight: "40px" }}>
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => navigate(`/order-management/view/${row?._id}`)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    
  ];

  // Fetch dealer data
  useEffect(() => {
    const body = {
      searchItem: searchItem || "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: status,
    };

    orderManagementApi
      .getAll(body)
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data?.result);
          setTotalPages(res?.data?.data?.totalRecords);
        }
      })
      .catch((err) => errorHandler(err));
  }, [searchItem, currentPage, pageSize, refresh, status]);

  const handlePageChange = (page) => setCurrentPage(page);

  const handleStatusChange = async (id, newStatus) => {
    try {
      const res = await orderManagementApi.changeStatus({
        id,
        orderStatus: newStatus,
      });
  
      if (res.status === 200) {
        toast.success(res?.data?.message || "Status updated successfully");
        setRefresh(!refresh); // Trigger a data refresh
      } else {
        toast.error(res?.data?.message || "Failed to update status");
      }
    } catch (err) {
      errorHandler(err);
      toast.error("Error occurred while updating status");
    }
  };
  

  return (
    <Card>
      <Helmet>
        <title>Order Management</title>
      </Helmet>
      <CardHeader title="Order Management" />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <TableFilter
          data={ORDER_STATUS_OPTIONS_LIST}
          value={status}
          setValue={setStatus}
          filterName="Status"
        />
        <Input
          value={searchItem}
          placeholder="Search"
          onChange={(e) => setSearchItem(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchItem("")}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
          sx={{ height: "40px", ml: 2, mr: 3}}
        />
      </Box>
      <CardContent>
        <DataTable
        customStyles={{
          headCells: {
            style: {
              fontSize: "15px",
              fontWeight: "bold",
            },
          },
        }}
          columns={columns}
          data={data || []}
          pagination
          paginationServer
          paginationTotalRows={totalPages}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          paginationRowsPerPageOptions={[2,5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(1); 
          }}
        />
      </CardContent>
      
    </Card>
  );
}
