import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { toast } from "react-toastify";
import {
  Box,
  CardHeader,
  CardContent,
  Grid,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { removeSpaces } from "../../utils/helpers";
import EditorJodit from "../../components/editor/editor";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { EmailTemplateSchema } from "../../utils/Schema";
import ApiService from "../../services/categoryManagementApi";

const { emailTemplateApi } = ApiService;

const AddEmail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const pageType = id ? "Update" : "Add";
  const [emailData, setEmailData] = useState("");
  //   const slug = state?.rowData?.slug;

  useEffect(() => {
    if (!id) return;
    const fetchData = async () => {
      try {
        const { data } = await emailTemplateApi.getById({ id });
        setEmailData(data.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: emailData?.title || "",
      slug: emailData?.slug || "cms-list",
      subject: emailData?.subject || "cms-list",
      description: emailData?.content || "",
      contentType: emailData?.contentType || "",
    },
    validationSchema: EmailTemplateSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(values, { setSubmitting }) {
    console.log("Hello");
    setSubmitting(true);
    try {
      values = removeSpaces(values);
      const valueData = {
        title: values.title,
        subject: values.subject,
        content: values.description,
      };

      if (id) valueData["id"] = id;
      const resp = id
        ? await emailTemplateApi.update(valueData)
        : await emailTemplateApi.add(valueData);
      // const resp = await cmsApi[pageType.toLowerCase()](valueData);
      if (resp.data.status) {
        toast.success(resp.data.message);
        navigate(-1);
      } else {
        toast.error(resp.data.message);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Box>
      <Helmet>
        <title>{pageType} Email</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${pageType} Email`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField name="title" label="Title" formik={formik} />
                </Grid>
                <Grid item xs={6}>
                  <FormField name="subject" label="Subject" formik={formik} />
                </Grid>
                <Grid item xs={12}>
                  <label>Message</label>
                  <EditorJodit
                    formik={formik}
                    fieldName="content"
                    onChange={(val) => formik.setFieldValue("description", val)}
                    data={formik.values.description}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <FormHelperText error>
                      {formik.errors.description}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Stack
                spacing={2}
                mt={3}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <LoadingButton text={pageType} loading={formik.isSubmitting} />
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

const FormField = ({ name, label, formik, disabled = false }) => (
  <Grid item xs={12}>
    <TextField
      fullWidth
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      required
      disabled={disabled}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
    />
  </Grid>
);

export default AddEmail;