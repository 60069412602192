import React from 'react';
import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';

export default function TableFilter({ value, setValue, data, filterName }) {
  const handleRemoveItem = (idx) => {
    const currentValue = [...value];
    currentValue.splice(idx, 1);
    setValue([...currentValue]);
  };

  // Utility function to get the display label
  const getDisplayValue = (selected) => {
    if (selected.length === 0) return "None"; // If no value selected
    if (selected.length === 1) return data.find((item) => item.value === selected[0])?.label || "None";
    const firstLabel = data.find((item) => item.value === selected[0])?.label || "None";
    return `${firstLabel} +${selected.length - 1}`;
  };

  return (
    <FormControl variant="outlined" sx={{ minWidth: "150px", marginRight: "10px" }} size="small">
      <InputLabel id="demo-select-small-label">{filterName}</InputLabel>
      <Select
        labelId="demo-mutiple-chip-checkbox-label"
        id="demo-mutiple-chip-checkbox"
        multiple
        label={filterName}
        value={value}
        onChange={(e) => {
          const val = e.target.value;
          setValue([...val]);
        }}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={(selected) => (
          <Tooltip title={selected.map((val) => data.find((item) => item.value === val)?.label).join(", ")}>
            <div>
              <Chip
                key={selected[0]}
                label={getDisplayValue(selected)}
                clickable
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={() => { handleRemoveItem(0); }}
              />
            </div>
          </Tooltip>
        )}
      >
        {data.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={value.includes(item.value)} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}