import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import ApiService from "../../services/categoryManagementApi";
import { capitalize } from "../../utils/helpers";
import { formatOrderStatus } from "../../utils/CommonFunction";

const { orderManagementApi } = ApiService;

const OrderManagementView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await orderManagementApi.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>Order Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Order View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Stack spacing={2}>

                    <Typography variant="h6">Customer Name</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.userId?.name || "N/A"}
                    </Typography>
                    <Divider />

                    <Typography variant="h6">Order ID</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.orderNumber || "N/A"}
                    </Typography>
                    <Divider />

                    <Typography variant="h6">Order Date/Time</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.scheduleDate ? new Date(data.scheduleDate).toLocaleString() : "N/A"}
                    </Typography>
                    <Divider />

                    <Typography variant="h6">Order Items</Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.products?.map((product, index) => (
                            <TableRow key={index}>
                              <TableCell>{product.name}</TableCell>
                              <TableCell align="center">{product.qty}</TableCell>
                              <TableCell align="right">${product.price}</TableCell>
                              <TableCell align="right">${product.qty * product.price}</TableCell>
                            </TableRow>
                          )) || (
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No Items Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Divider />

                    <Typography variant="h6">Order Status</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {formatOrderStatus(data?.orderStatus?.stage) || "pending"}
                    </Typography>
                    <Divider />

                    <Typography variant="h6">Delivery Address</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.userAddress
                        ? `${data.userAddress.flatNumber}, ${data.userAddress.area}, ${data.userAddress.city}, ${data.userAddress.state}, ${data.userAddress.pinCode}`
                        : "N/A"}
                    </Typography>
                    <Divider />

                    <Typography variant="h6">Payment Status</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      {data?.paymentStatus || "N/A"}
                    </Typography>
                    <Divider />

                    <Typography variant="h6">Total Amount</Typography>
                    <Typography variant="body1" sx={{ overflowWrap: "break-word" }}>
                      ${data?.total || "N/A"}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default OrderManagementView;
