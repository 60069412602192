import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, CardHeader, CardContent, Stack, Box, Divider } from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { IMAGE_BASE } from "../../utils/constants";
import ApiService from "../../services/categoryManagementApi";
import { PictureAsPdf } from "@mui/icons-material";
import { fDateTime } from "../../utils/formatTime";

const { paymentApi } = ApiService;

const PaymentManagementView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await paymentApi.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>Payment Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Payment View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant="h6">Name</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.userId?.name || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Email</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.userId?.email || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Price</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      ${data?.total || ""}
                    </Typography>
                    <Divider></Divider>


                    <Typography variant="h6">Order Number</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.orderNumber || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Payment Method</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.paymentMethod || ""}
                    </Typography>
                    <Divider></Divider>

                    {data.cancelDetails ?
                      <>
                        <Typography variant="h6">Refund Status</Typography>
                        <Typography
                          variant="body1"
                          sx={{ overflowWrap: "break-word" }}
                        >
                          {data?.cancelDetails?.refundStatus || ""}
                        </Typography>
                        <Divider></Divider>
                        <Typography variant="h6">Refunded Amount</Typography>
                        <Typography
                          variant="body1"
                          sx={{ overflowWrap: "break-word" }}
                        >
                          {data?.cancelDetails?.refundedAmount || ""}
                        </Typography>
                        <Divider></Divider>
                      </>
                      : null}
                    <Typography variant="h6">Payment Status</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.paymentStatus || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Oder Date</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {fDateTime(data?.createdAt) || ""}
                    </Typography>
                    <Divider></Divider>

                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default PaymentManagementView;