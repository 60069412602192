import React, { useEffect, useState } from "react";
import {
  Box, Button, Card, CardContent, CardHeader, Input, InputAdornment,
  IconButton, Tooltip, Dialog, DialogActions, DialogTitle, TextField
} from "@mui/material";
import { Close as CloseIcon, RefreshRounded, Visibility as VisibilityIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";
import ApiService from "../../services/categoryManagementApi";
import { fDateTime } from "../../utils/formatTime";
import TableFilter from "../../components/shared/TableFilter";
import { PAYMENTSTATUS_OPTIONS_LIST } from "../../utils/constants";

const { paymentApi } = ApiService;

export default function PaymentManagement() {
  const navigate = useNavigate();

  const [searchItem, setSearchItem] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [verifyDialog, setVerifyDialog] = useState(false);
  const [refundPrice, setRefundAmount] = useState("");
  const [refundError, setRefundError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);

  const handleVerifyClose = () => setVerifyDialog(false);

  const confirmStatusChange = (id, currentStatus, newStatus) => {
    if (currentStatus === newStatus) return;

    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to "${capitalize(newStatus)}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleStatusChange(id, newStatus);
      }
    });
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      const payload = { id, paymentStatus: newStatus };
      const response = await paymentApi.updateUser(payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        setRefresh(!refresh); // Refresh the data
      } else {
        toast.error("Failed to update payment status.");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleVerifySubmit = async () => {
    if (!refundPrice) {
      setRefundError("Refund amount is required.");
      return;
    }
  
    if (refundPrice <= 0) {
      setRefundError("Refund amount must be greater than 0.");
      return;
    }

    if (refundPrice > totalPrice) {
      setRefundError(`Refund amount cannot exceed the total price of $${totalPrice}`);
      return;
    }

    const regex = /^\d+(\.\d{1,2})?$/;
    if (!regex.test(refundPrice)) {
      setRefundError("Refund amount must have at most two decimal places.");
      return;
    }
    try {
      const payload = { id: statusId, amount: refundPrice };
      const response = await paymentApi.refundAmount(payload);
      toast.success(response?.data?.message);
      setVerifyDialog(false);
      setRefundAmount('')
      setRefresh(!refresh);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const body = {
      searchItem: searchItem || "",
      pageNumber: currentPage,
      pageSize: pageSize,
      paymentStatus: status,
      startDate: startDate || null,
      endDate: endDate || null,
    };

    paymentApi
      .getAll(body)
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data?.result);
          setTotalPages(res?.data?.data?.totalRecords);
        }
      })
      .catch((err) => errorHandler(err));
  }, [searchItem, currentPage, pageSize, refresh, status, startDate, endDate]);

  const handlePageChange = (page) => setCurrentPage(page);

  const columns = [
    {
      name: "S.No.",
      sortable: false,
      cell: (row, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => capitalize(row?.userName),
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => capitalize(row?.userEmail),
    },
    {
      name: "Order Number",
      sortable: true,
      selector: (row) => capitalize(row.orderNumber),
    },
    {
      name: "Payment Date",
      sortable: true,
      selector: (row) => fDateTime(row.createdAt),
    },
    {
      name: "Amount Paid",
      sortable: true,
      selector: (row) => `$${row.total}`,
    },
    {
      name: "Transaction ID",
      sortable: true,
      selector: (row) => row.paymentTransactionId || "-",
    },
    {
      name: "Payment Status",
      sortable: true,
      cell: (row) => (
        <select
          value={row.paymentStatus}
          onChange={(e) => confirmStatusChange(row._id, row.paymentStatus, e.target.value)}
          disabled={row.paymentStatus.toLowerCase() === "success"}
          style={{
            padding: "5px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            background: "#fff",
          }}
        >
          {PAYMENTSTATUS_OPTIONS_LIST.map((status) => (
            <option key={status.value} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
      ),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          <Tooltip title="Refund" placement="right">
            <IconButton
              color="primary"
              onClick={() => {
                setVerifyDialog(true);
                setStatusId(row._id);
                setTotalPrice(row.total);  // Set the total price
                setRefundAmount(row.total);  // Set the refund amount to the total price
              }}
            >
              <RefreshRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => navigate(`/payment-management/view/${row?._id}`)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];


  return (
    <Card>
      <Helmet>
        <title>Payment Management</title>
      </Helmet>
      <CardHeader title="Payment Management" />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>

      <TextField
          label="Start Date"
          type="date"
          value={
            startDate ? new Date(startDate).toISOString().split("T")[0] : ""
          }
          onChange={(e) => {
            const newDate = e.target.value ? new Date(e.target.value) : null;
            setStartDate(newDate);
          }}
          placeholder="Start Date"
          InputLabelProps={{ shrink: true }}
          size="small"
          sx={{ marginRight: 2 }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDate ? new Date(endDate).toISOString().split("T")[0] : ""}
          onChange={(e) => {
            const selectedEndDate = e.target.value
              ? new Date(e.target.value)
              : null;
            if (selectedEndDate) {
              selectedEndDate.setHours(23, 59, 59); 
            }
            setEndDate(selectedEndDate);
          }}
          placeholder="End Date"
          InputLabelProps={{ shrink: true }}
          size="small"
          sx={{ marginRight: 2 }}
        />

        <TableFilter
          data={PAYMENTSTATUS_OPTIONS_LIST}
          value={status}
          setValue={setStatus}
          filterName="Status"
        />
        <Input
          value={searchItem}
          placeholder="Search"
          onChange={(e) => setSearchItem(e.target.value)}
          sx={{ marginRight: 2 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchItem("")}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
        />

        <Button
          variant="outlined"
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
            setStatus([])
            setSearchItem('')
          }}
          sx={{ marginRight: 2 }}
        >
          Clear
        </Button>
      </Box>
      <CardContent className="custom-headertable">
        <DataTable
          customStyles={{
            headCells: {
              style: {
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
          }}
          columns={columns}
          data={data || []}
          pagination
          paginationServer
          paginationTotalRows={totalPages}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          paginationRowsPerPageOptions={[2, 5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(1);
          }}
        />
      </CardContent>

      <Dialog
        open={verifyDialog}
        onClose={handleVerifyClose}
      >
        <CardHeader title="Refund Amount" titleTypographyProps={{ align: "center", variant: "h6" }} />

        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 3,
          }}
        >
          <Input
            placeholder="Refund Amount"
            value={refundPrice}
            onChange={(e) => {
              const newValue = e.target.value
                .replace(/[^0-9.]/g, "") 
                .replace(/^(\d*\.\d{2})\d*$/, "$1"); 
              setRefundAmount(newValue);
              setRefundError("");
            }}
            fullWidth
            sx={{
              marginBottom: 2,
              padding: "12px",
              maxWidth: 300,
            }}
            inputProps={{
              maxLength: 10,
            }}
          />

          {refundError && (
            <Box sx={{ color: "red", fontSize: "14px", marginBottom: 2 }}>
              {refundError}
            </Box>
          )}

          <Button
            color="primary"
            variant="contained"
            onClick={handleVerifySubmit}
            sx={{
              padding: "10px 20px",
              minWidth: 150,
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}